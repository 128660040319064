import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { DownloadListProps } from './models';

import './DownloadList.scss';

const DownloadList: FC<{ data: DownloadListProps }> = ({
  data: {
    downloadList: { urls, seo, items },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const location = useLocation();
  const eventData = { eventType: 'page_view', tagData: { pageTemplate: location.pathname } };

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
        tagEventData={eventData}
      />
      <div className="mx-auto section-container download-list">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col px-0">
              {items.map(({ file, title, description, image }, index) => (
                <div
                  className={`container-fluid download-list__item ${
                    index > 0 ? 'mask' : 'pt-1 pt-md-5 pb-5 pb-mb-0'
                  }`}
                  key={title}
                >
                  <div
                    className={`row py-5 ${
                      index % 2 === 0 ? 'text-start' : 'text-end flex-row-reverse'
                    }`}
                  >
                    <div className="col d-flex align-items-center justify-content-center text-center text-md-start">
                      <div className="download-list__item-text">
                        <DangerouslySetInnerHtml
                          html={title}
                          element="h1"
                          className="mb-4 text-uppercase"
                        />
                        <div className="d-block d-md-none px-5">
                          <Image imageData={image} alt={title} />
                        </div>
                        <DangerouslySetInnerHtml html={description} element="p" className="mb-4" />
                        <a
                          href={file.url}
                          className={`btn btn-${index % 2 === 0 ? 'primary' : 'secondary'}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {file.name}
                        </a>
                      </div>
                    </div>
                    <div className="col align-items-center justify-content-center d-none d-md-flex">
                      <Image imageData={image} alt={title} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    downloadList(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      items {
        title
        description
        file {
          name
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 420)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 420)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 420)
            }
          }
        }
      }
    }
  }
`;

export default DownloadList;
