import { useLocation } from '@reach/router';

import { BrandInfo, PageExtractor } from '../models';

const Extractor = (
  pageHref: string,
  data: PageExtractor,
  brandInfo: BrandInfo,
  type: string,
  imageurl: string
): object => {
  const url = new URL(pageHref);
  const path = useLocation();

  let jsonResponse = {};
  const keywords = data.metaKeywords.join(',');

  switch (type) {
    case 'WebSite':
      jsonResponse = {
        url: url + path.pathname.substring(1),
        potentialAction: {
          '@type': 'SearchAction',
          target: '{search_term_string}',
          'query-input': 'required name?search_term_string',
        },
      };
      break;

    case 'Recipe':
      jsonResponse = {
        url: url + path.pathname.substring(1),
        image: url + imageurl.substring(1),
        description: data.metaDescription,
        keywords,
        author: {
          '@type': 'Person',
          name: data.author ? data.author : brandInfo.brandName,
        },
      };
      break;

    default:
      jsonResponse = {
        url: url + path.pathname.substring(1),
      };
      break;
  }

  return jsonResponse;
};

export default Extractor;
